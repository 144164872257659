<template>
  <div>
    <header-slot>

    </header-slot>
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'ti-reports-daily' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >DAILY</b-nav-item>
      <b-nav-item
        :to="{ name: 'ti-reports-general' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >GENERAL</b-nav-item>

    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">

      <router-view :key="$route.name" />
    </b-card>

  </div>
</template>

<script>

export default {

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>

<style scoped>
</style>
